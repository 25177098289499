import axios from 'axios'

export const csrf = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }

const Api = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': csrf.content
    }
  }
})


export default Api