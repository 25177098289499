
import { Controller } from "stimulus"
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  static targets = ["photo"]

  connect() {
    this.photos = this.photoTargets.map(el => {
      return {
        src: el.dataset.src,
        thumb: el.dataset.thumb,
      }
    })

  }

  open(event) {
    event.preventDefault()
    Fancybox.show(this.photos)
  }
}











