import { getCookie, setCookie } from "../lib/cookies";

export default () => {
  const cookie = getCookie('cookies_accepted');
  if (cookie === '') {
    const closeBtn = document.getElementById('accept-cookies-btn')
    const message = document.getElementById('cookies-call')
    message.classList.remove('d-none')
    closeBtn.addEventListener('click', () => {
      message.parentNode.removeChild(message);
      setCookie('cookies_accepted', 'true', 365);
    })
  }
  if (cookie === 'true') {
    const message = document.getElementById('cookies-call')
    message.parentNode.removeChild(message);
  }

}