import { getCookie, setCookie } from "../lib/cookies";

export default () => {
  const cookie = getCookie('wpp_btn');
  if (cookie === '') {
    const closeBtn = document.getElementById('wpp-close')
    const message = document.getElementById('wpp-message')
    message.classList.remove('d-none')
    closeBtn.addEventListener('click', () => {
      message.parentNode.removeChild(message);
      setCookie('wpp_btn', 'false', 5);
    })
  }
  if (cookie === 'false') {
    const message = document.getElementById('wpp-message')
    message.parentNode.removeChild(message);
  }

}