// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Shuffle from 'shufflejs';

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card", "container"]

  connect() {
    this.shuffle = new Shuffle(this.containerTarget, {
      itemSelector: '.place-card',
      sizer: '.place-card'
    })

    setTimeout(() => {
      this.update()
    }, 100)

  }

  update() {
    this.shuffle.update()
  }

  filter(e) {
    const cat = e.currentTarget.dataset.cat
    if (cat === "all") {
      this.shuffle.filter(Shuffle.ALL_ITEMS)
      return
    }

    this.shuffle.filter(cat)

  }

}
