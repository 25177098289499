
import { Controller } from "stimulus"
import markerBlank from '../../images/maps/markers/blank.png'

export default class extends Controller {
  static targets = ["map", "marker"]

  connect() {
    if (typeof (google) !== "undefined") {
      this.initMap()
    }
  }

  initMap(e = {}) {
    if (typeof this.map !== "undefined") {
      return
    }


    const lat = -23.6
    const lng = -46.8
    const zoom = 13

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(Number(lat), Number(lng)),
      zoom
    })

    this.setMarkers()


  }

  setMarkers() {
    this.markerTargets.forEach(m => {
      const lat = m.dataset.latitude
      const lng = m.dataset.longitude
      const pos = m.dataset.position


      const marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(Number(lat), Number(lng)),
        icon: require(`../../images/maps/markers/${pos}.png`),
        animation: google.maps.Animation.DROP,
      })

      if (pos === "1") {
        this.map.setCenter(new google.maps.LatLng(Number(lat), Number(lng)))
      }

      marker.setPosition(new google.maps.LatLng(Number(lat), Number(lng)))
      marker.setVisible(true)
      marker.addListener("click", () => {
        let anchor = document.querySelector(`#step_pos_${pos}`)
        anchor.scrollIntoView({ behavior: "smooth" })
      });
    })
  }

}