
import { loadScript } from "@paypal/paypal-js"
import Api from '../lib/api'

// "buyer-country": "BR",

const _payments = (elID) => {
  loadScript({
    "client-id": "AVRcbvl-37_9v2K9zqBdSoE9yHraRm7v-wnNeOaFt111sMQY4NDxQbgs0nrZPo7tvolCDiGz-fiZsLAr",
    "currency": "BRL",
    "locale": 'pt_BR',
    "components": "buttons",
    "vault": true,
  })
    .then((paypal) => {
      paypal
        .Buttons({
          // Sets up the transaction when a payment button is clicked
          createOrder: async (data, actions) => {
            const response = await Api.post('/orders')
            const order = response.data
            return order.pp_id
          },
          // Finalize the transaction after payer approval
          onApprove: async (data, actions) => {
            const response = await Api.post(`/orders/${data.orderID}/capture`)
            const order = response.data
            window.location.replace(`/orders/${order.id}`)
          },
          onCancel: async (data) => {
            const response = await Api.post(`/orders/${data.orderID}/cancel`)
            if (response.status !== 200) {
              console.error("Error cancelling order")
            }
          },
          onError: async (data) => {
            const response = await Api.post(`/orders/${data.orderID}/cancel`)
            if (response.status !== 200) {
              console.error("Error cancelling order")
            }
          },
        })
        .render(elID)
        .catch((error) => {
          console.error("failed to render the PayPal Buttons", error);
        });

    })
    .catch((err) => {
      console.error("failed to load the PayPal JS SDK script", err);
    });

}



export default (elID) => {
  const el = document.getElementById(elID)
  if (el) {
    _payments(`#${elID}`)
  }
}