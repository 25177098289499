// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
import '../css/application.scss'

import Rails from "@rails/ujs"
import Masks from '../components/masks'
import Payments from '../components/paypal'
import WppBtn from '../components/wpp_btn'
import CookiesCall from '../components/cookies_call'


Rails.start()

import "controllers/app"
const sleep = ms => new Promise(r => setTimeout(r, ms));
const images = require.context('../images', true)

window.initMap = async (...args) => {
  const event = new CustomEvent("google-maps-callback", { detail: { teste: "ABC" }, bubbles: true })
  await sleep(1000)
  window.dispatchEvent(event)
}


document.addEventListener('DOMContentLoaded', () => {
  Masks()
  WppBtn()
  CookiesCall()
  Payments("paypal-button-container")
})